body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: #282c34;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.logo {
  height: 15vmin;
  width: 15vmin;
  pointer-events: none;
  margin: 5px;
  padding: 5px;
}

@media screen and (max-width: 600px) {
  .app {
    grid-template-columns: 1fr;
  }

  .logo {
    height: 30vmin;
    width: 30vmin;
    margin: 10px;
    padding: 10px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: rotate infinite 20s linear;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
